export enum Status {
  CreationProcess = "creationProcess",
  Cancelled = "cancelled",
  Closed = "closed",
  PendingCreation = "pendingCreation",
  PendingApproval = "pendingApproval",
  Refunded = "refunded",
  PendingRefund = "pendingRefund",
  PendingReceipt = "pendingReceipt",
  PartiallyReceived = "partiallyReceived",
  PartiallyReceivedPendingRefund = "pendingRefundPartReceived",
}
