import React from "react";
import classes from "./ReturnStatusTag.module.scss";
import { Status } from "../../../enums/Status";

type ReturnStatusTagProps = {
  status?: string;
};

const ReturnStatusTag: React.FC<ReturnStatusTagProps> = ({ status }) => {
  if (!status) {
    return null;
  }

  const statusMap: {
    [key: string]: { text: string };
  } = {
    [Status.PendingCreation]: {
      text: "Pending Creation",
    },
    [Status.Refunded]: {
      text: "Refunded",
    },
    [Status.PendingRefund]: {
      text: "Pending Refund",
    },
    [Status.PartiallyReceived]: {
      text: "Refunded",
    },
    [Status.PartiallyReceivedPendingRefund]: {
      text: "Partially Received/Pending Refund",
    },
    [Status.PendingReceipt]: {
      text: "Pending Receipt",
    },
    [Status.PendingApproval]: {
      text: "Pending Approval",
    },
    [Status.Cancelled]: {
      text: "Cancelled",
    },
    [Status.Closed]: {
      text: "Cancelled",
    },
  };

  const { text } = statusMap[status] || { text: "" };

  return (
    <div className={`${classes.statusTag} ${classes[status]}`}>{text}</div>
  );
};

export default ReturnStatusTag;
