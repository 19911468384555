import { httpsCallable, HttpsCallable } from "firebase/functions";
import useSWR from "swr";
import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import useFunctionsService from "./useFunctionsService";
import useAuth from "../hooks/useAuth";
import { InvoiceData } from "../entities/InvoiceInfo";

type FetchInvoiceInformationParams = {
  token?: string;
  orderNumber: string;
  zipCode: string;
  domain: string;
};

const useGetInvoiceInformation = () => {
  const { functions } = useFunctionsService();
  const { credential } = useAuth();
  const token = useMemo(() => credential?.token, [credential]);

  const [searchParams] = useSearchParams();
  const orderNumber = searchParams.get("order-number") || undefined;
  const zipCode = searchParams.get("zip-code") || undefined;
  const domain = useMemo(() => window.location.hostname, []);

  const getStatusOrderFormFunction: HttpsCallable<
    FetchInvoiceInformationParams,
    InvoiceData
  > = useMemo(
    () => httpsCallable(functions, "unlockreturns-getInvoiceInformation"),
    [functions]
  );

  const getStatusOrderForm = useCallback(
    async (statusOrderForm: FetchInvoiceInformationParams) => {
      try {
        const result = await getStatusOrderFormFunction({
          token,
          ...statusOrderForm,
        });
        return result.data;
      } catch (e) {
        console.error(e);
        throw new Error(
          "Unexpected error while authenticating, try again later!"
        );
      }
    },
    [token, getStatusOrderFormFunction]
  );

  const { data, error, isValidating, mutate } = useSWR<InvoiceData>(
    token && orderNumber && zipCode && domain
      ? [orderNumber, zipCode, domain]
      : null,
    ([orderNumber, zipCode, domain]) =>
      getStatusOrderForm({
        token,
        orderNumber,
        zipCode: String(zipCode),
        domain: String(domain),
      }),
    {
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
    }
  );

  return {
    data,
    error,
    isValidating,
    mutate,
    getStatusOrderForm,
  };
};

export default useGetInvoiceInformation;
